@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "CeraPro";
  font-style: normal;
  font-display: swap;
  src:
    local("CeraPro"),
    local("CeraPro"),
    url(/fonts/CERAPRO.woff) format("woff");
}

@font-face {
  font-family: "americana";
  font-style: normal;
  font-display: swap;
  src:
    local("americana"),
    local("americana"),
    url(/fonts/americana.otf) format("opentype");
}

@font-face {
  font-family: "Montecarlo";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    local("Montecarlo"),
    local("Montecarlo"),
    url(/fonts/MONTECARLO.woff) format("woff");
}

@font-face {
  font-family: "Shango";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    local("Shango"),
    local("Shango"),
    url(/fonts/SHANGO-REGULAR.woff) format("woff");
  src:
    local("Shango"),
    local("Shango"),
    url(/fonts/SHANGO-REGULAR.ttf) format("truetype");
}

@font-face {
  font-family: "jost";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/jost-medium.ttf) format("truetype");
}

@font-face {
  font-family: "jost";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/jost-regular.ttf) format("truetype");
}

@layer base {
  html {
    font-family: "jost";
    color: #403732;
  }
}

@layer utilities {
  .font-sans {
    font-family: "CeraPro";
    color: #5e5047;
  }
}

body {
}

#root {
  composes: bg-white from global;
  composes: relative from global;
  composes: z-foreground from global;
}

@media (min-height: 768px) {
  #container {
    min-height: 60rem;
  }
}

.dialogopen {
  overflow: hidden;
  width: 100%;
}

a:focus-visible,
select:focus-visible,
input:focus-visible,
textarea:focus-visible,
button:focus-visible {
  outline: unset;
}

button {
  outline: none;
}

select {
  background: #fff;
}

input,
textarea {
  color: #5e5047 !important;
}

input:focus {
  border: 1px #80583f solid !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill {
  color: #5e5047 !important;
}

.bg-bgdeeptheme {
  border: 1px #80583f solid;
}

.bg-bgdeeptheme:hover {
  background: unset;
  color: #80583f;
}

.bg-bglighttheme:hover {
  background: #80583f;
  color: #fff;
}

.ant-input:focus {
  border-color: #80583f !important;
}

.ant-picker .ant-picker-input input {
  border: none !important;
}

.ant-picker-panel-container {
  background-color: #f8f3ef !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view {
  color: #5e5047 !important;
}

.ant-picker-dropdown .ant-picker-content th {
  text-transform: uppercase;
  color: #5e5047 !important;
}

.ant-select-selector {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-select-selector input:focus {
  border: none !important;
}

@media (max-width: 959px) {
  #chatra.chatra--side-bottom {
    bottom: 15% !important;
  }
}

.ant-picker-today-btn {
  color: #5e5047 !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #5e5047 !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #80583f !important;
}

.ant-picker-dropdown .ant-picker-panel-container {
  border-radius: 0;
}

.ant-image-preview-root .ant-image-preview-img-wrapper::before {
  content: unset;
}

.ant-image-preview-root .ant-image-preview-img-wrapper .ant-image-preview-img {
  margin: 0 auto;
}

a {
  text-underline-offset: 4px;
}

@keyframes loading-pulse {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0.1;
    transform: translateY(-12px);
  }
}

@media (min-width: 960px) {
  #category-filter-sidebar::-webkit-scrollbar,
  .category-color-select::-webkit-scrollbar,
  #mini-cart-product-list::-webkit-scrollbar {
    width: 6px;
  }

  #category-filter-sidebar::-webkit-scrollbar-thumb,
  .category-color-select::-webkit-scrollbar-thumb,
  #mini-cart-product-list::-webkit-scrollbar-thumb {
    background-color: #e7ded5;
  }

  #category-filter-sidebar::-webkit-scrollbar-thumb:hover,
  .category-color-select::-webkit-scrollbar-thumb:hover,
  #mini-cart-product-list::-webkit-scrollbar-thumb:hover {
    background-color: #e7ded5;
  }

  #category-filter-sidebar::-webkit-scrollbar-track,
  .category-color-select::-webkit-scrollbar-track,
  #mini-cart-product-list::-webkit-scrollbar-track {
    background-color: transparent;
  }

  #category-filter-sidebar,
  .category-color-select,
  #mini-cart-product-list {
    scrollbar-color: #e7ded5 transparent;
    scrollbar-width: thin;
  }
}

#frequently-bought-together-container::-webkit-scrollbar {
  width: 6px;
}

#frequently-bought-together-container::-webkit-scrollbar-thumb {
  background-color: #e7ded5;
}

#frequently-bought-together-container::-webkit-scrollbar-thumb:hover {
  background-color: #e7ded5;
}

#frequently-bought-together-container::-webkit-scrollbar-track {
  background-color: transparent;
}

#frequently-bought-together-container {
  scrollbar-color: #e7ded5 transparent;
  scrollbar-width: thin;
}

.faq-popup .ant-select-item-option-content {
  font-size: 15px;
}
